
.sockets {
    position: fixed;
    z-index: 1001;
    top: 0;
    right: 10px;
    font-size: 14px;
  }

  .ui  {cursor: pointer;}

  .btns {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .meetingIcons, .startMeetingBtn {
    position: absolute;
    bottom: 2.7%;
    right: 2.7%;
    /* right: 100px; */
    z-index: 1002;
  }

  .callEnd {
    position: fixed;
    bottom: calc(2.7% + 5px);
    right: calc(2.7% + 60px);
    /* right: 100px; */
    z-index: 1002;
    background-color: rgba(255,0,0,0.7);
    padding: 0 10px;
    border-radius: 20px;

  }

  .callEnd svg path{
    fill: white;
  }

  .icon {
    /* background: rgba(255,255,255,0.3); */
  }

  .icon svg{
    /* width */
    fill: #888;
    width: 32px;
    height: 32px;
    transition: all 0.2s ease;
  }
  
  .on svg {
    fill: white;
  }

  .panel {
    position: fixed;
    right: -10px;
    height: calc(100% - 40px);
    width: 240px;
    padding: 20px;
    background: rgba(0,0,0,0.8);
    transform: translateX(270px);
    transition: all 0.2s ease;
    backdrop-filter: blur(10px);
  }

  .panel.on {
    transform: translateX(0);
  }

  .clients {
    flex-direction: column;
    padding: 20px 0;
    display: flex;
    gap: 10px;
    float: left;
    width: 100%;
  }
  
  .clientRow {
    display: grid;
    grid-template-columns: 1fr 20px 20px;
    gap: 10px;
    align-items: center;
  }

  .clientRow2, .lastMessage {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    align-items: center;
  }

  .client svg {
    width: auto;
    height: 18px;
  }

  .statusLight {
    width: 10px;
    height: 10px;
    border-radius: 28px;
    background: rgba(255,191,0,1);
    display: inline-block;
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  .statusLight.on {
    background: green;
    animation-name: none;
  }

  .copyBtn {
    position: relative;
    background: none;
    display: inline-block;
    margin: 10px 0;
    padding: 10px 20px;
    border: 1px solid white;
    border-radius: 4px;
  }

  .clientCountBtn {
    position: absolute;
    top: -10px;
    left: 24px;
  }

  .clientCount {
    color: #888;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #888;
    border-radius: 40px;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255,0);
    transition: all 0.2s ease;


  }
  .on .clientCount {
    color: #fff;
    border-color: #fff;
  }

  .clientCount.red {
    background: rgba(255,191,0,1);
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  .receiveIcon {
    opacity: 0.8;
  }

  @keyframes color {
    0% {
      background-color: rgba(255,191,0,0.35);
    }
    50% {
      background-color: rgba(255,191,0,1);
      /* color: #746426; */
    }
    100% {
      background-color: rgba(255,191,0,0.35);
    }
  }

  .meetingWelcome {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    background: rgba(0,0,0,0.7);
    position: fixed;
    left: 0;
    top: 0;
    backdrop-filter: blur(20px);
    gap: 10px;
   z-index: 1001;
}

.meetingWelcome h1, .meetingWelcome p {
    margin: 0;
    padding: 0;
    line-height: 100%;
}

.welcomeInput {
  margin-top: 10px;
  margin-bottom: 5px;
}

.button {
    cursor:pointer;
    border-bottom:1px solid rgba(255,255,255,0.5);
    padding: 4px 0;
    font-size: 18px;
    color: white;
    cursor: pointer;
    border-radius: 0;
    transition: all 0.3s ease;
    display: inline-block;
}

.button:hover {
  transform: translateY(-4px);
}

.center {
    max-width: 500px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap:20px;

}

.welcomeInput.error {
  border-color: red;
  transition: all 0.3s ease;
}

span.error {
  padding: 5px;
  background-color: rgba(255,0,0,0.5);
  transition: all 0.3s ease;
}
/* 
.meetingIcons :global(path){  
  fill:var(--svg-stroke) !important;
  transition: fill 0.25 ease-in-out
}
.meetingIcons:hover :global(path){
  fill:var(--svg-stroke-hover) !important;
} */
