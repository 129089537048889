.heading{
    position:absolute;
    color:#0F3557;
    top:56px;
    // font-weight: 600;
    font-family: "GothamBold";
    font-size: 28px;

}
.letterbox{
    position: relative;
    width:85%;
    margin : 0 auto;

}

.container{
    background-color:#EFEFF0;
    
}
.scrollContainer{
    display: flex;
    justify-content: center;
    overflow: hidden scroll;
    
}

.landingContainer {
    width:100%;
    min-height: 100vh;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    overflow-y: scroll;

}


.itemsContainer {
    width:100%;
    display: flex;
    flex-wrap: wrap; 
    position: relative;
    align-content: flex-start;
    height: calc(100vh - 161px);
    z-index: 0;
    margin-bottom:0px;
    padding-top: 160px;
    overflow-y: scroll;
    position: absolute;
    top: 0;
    gap: 30px;
    
  }

  .thumbnail{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width:384px;
    height: 267px;
    cursor: pointer;
}

.thumbnail img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border: 1px solid #878E91;
    border-radius: 4px;
    display: relative;
    transition: all .25s ease-in-out;
    overflow: hidden;
}

.thumbnail {
    aspect-ratio: 1.78;
}

.caption{
    width: 100%;
    text-overflow: ellipsis;
    /* margin-top:14px; */
    text-align: left;
    font-size: 16px;
    transition: all .25s ease-in-out;
    // font-weight: 600;
    font-family: "GothamMed";
    padding-top: 10px;
    float: left;
    height: 40px;
    display: flex;
    align-items: center;

}

.downloadBtn {
    padding: 0 20px;
}

:global(.serverApp) .downloadBtn {
    display: none;
}

.downloadBtn svg {
    fill: white;
}

// .itemContainer:hover img{
//     opacity: 0.85;   
// }

.itemContainer:hover .caption{ 
    color:var(--primary-accent);
    
    align-content: flex-start;
}


.backgroundImage {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
    background-position: cover;
}


.closeBtnContainer {
    z-index: 4;
 }

 .pdfBox {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: var(--primary-background);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
 }

 .pdf {
    height: auto;
    width: 84%;
    max-width: 2400px;
    position: relative;
    z-index: 1;
    
    // top: 5%;
    // aspect-ratio: 16/9;
 }

 .pdfPage {
    height: 100%;
    width: 100%;
    filter: drop-shadow(10px 3px 20px rgba(0,0,0,0.5));
    display: flex;
    // align-items: center;
    justify-content: center;
    
 }

 .pdfPage :global(canvas) {
    width: 100% !important;
    height: auto !important;
  
//    aspect-ratio: 16/9;

 }


 :global(.textLayer), :global(.annotationLayer) {
    display: none;
 }

 .loader {
    // z-index: 4;
    // position: fixed;
    // top: 50vh;
    // left: 50vw;
 }

 .loaderElePage {

 }

.controlsPill {
    height: calc(calc(38px + calc(var(--lerpAspect) * 4px)) * var(--lerpScale));
    // background-color: var(--round-btn-background-color);
    background-color: rgba(255,255,255,1);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    // box-shadow: 0px 3px 1px rgba(0,0,0,0.1);
    transition: all 0.3s ease;
    width: 100px;
    position: fixed;
    left: calc(50% - 50px);
    bottom: var(--sideIndent);
    z-index: 3;
}

:global(.receiver) .controlsPill,  :global(.serverApp) .controlsPill{
    display: none;
}


.controlsPill svg.off {
    opacity: 0.3
}


.controlsPill svg {
    padding: 0 4px;
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
}

.controlsPill svg:first-of-type {
    border-right: 1px solid #e6e6e6;
}

.pdfClose {
    position: absolute;
    bottom: var(--sideIndent);
    left: var(--sideIndent);
    display: flex;

    align-items: flex-end;
    z-index: 5;
    cursor: pointer;
}


:global(.receiver) .pdfClose, :global(.serverApp) .pdfClose {
    display: none;
}

.pageNumber {
    float: right;
    position: relative;
    top: -2vh;
    right: -6vw;
}