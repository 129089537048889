.topContainer {
    /* background-color: var(--primary-background); */
    background: white;
    overflow: hidden;
    --logoHeight:15%;
}

.menuBg{
    /* height:100%;
    overflow: hidden;
    object-fit: cover;
    object-position: left center;
    width:100%;
    background-color: var(--primary-background);
    object-fit: cover;
    object-position: center; */
}




.moduleHolder{
    left: 440px;
    top: 0px;
    height: calc(100% - 22px);
    width:calc(100% - 480px);
    padding: 22px 40px 0 0;
    position: absolute;
    pointer-events: all;
    overflow: hidden scroll;
   background-color: white;
   z-index: 0;
   transition: all 0.3s ease;

}

 
.moduleContainer[overlaymodule="true"] .moduleHolder{
    margin-top: 100%;
    opacity: 0;
  
}

:global(div[class*="narrative_onLanding__"]) .moduleHolder {
    opacity: 0;
    transition: all 0.3s ease;
}


::-webkit-scrollbar {
    display: none;
}


.controllerMenuLink{
    font-size: 20px;
    margin: 0;
}

.onSerial :global(span::after){
    /* color: rgba(244,150,0,1) !important;
    content:"• ";
    padding-left: 10px; */
}

.controllerMenuLinkRow {
    display: flex;
    /* gap: 40px; */
    width: 100%;
    justify-content: space-between;
    margin-top: 0px;
}

.controllerLinks {
    margin: 20px 0 0 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: calc(100vh - 200px);
    
}


:global(.linkOn).controllerMenuLink {
    color: var(--primary-accent) !important;
}

.serialBtn {
    width: 26px;
    height: 26px;
    position: relative;
    align-items: center;
    display: flex;
    top: -3px;
    position: relative;
    margin: 0 0 19px 0;
    transition: all 0.3s ease;

}

.serialBtnInUse.serialBtn {
    opacity: 0.6;
}

.serialBtn.serialBtnOff path {
    fill: var(--primary)
}

.serialBtn path {
    fill: var(--secondary);
}

.serialBtn .icon {
    width: 100%;
    height: 100%;
}

.controllerMenuLink:hover, .menuSubLink:hover {
    color: var(--primary) !important;
}

.controllerMenuLink:active, :global(.linkOn) .controllerMenuLink, .controllerMenuLink :global(.linkOn), :global(.linkOn) .controllerMenuLink:hover, :global(.linkOn) .controllerMenuLink .menuSubLink:hover,  :global(.linkOn).controllerMenuLink:hover{
    color:var(--secondary) !important;
}
 
.controllerMenuLinkRow:last-child {
    padding-bottom: 100px;
}




/* .controllerMenuLink.on .menuSubLink {
    color: var(--primary);
} */


/* .controllerMenuLink:last-child{
    margin:20px 0 ;
} */



.narrativeControlPanel {
    position: fixed;
    left: 0;
    top: 0;
    width: calc(100% - 400px);
    height: 100vh;
    padding: 22px 22px 22px 380px;
    z-index: 0;
     
}

.grid33 {
    display: grid;
    grid-template-columns: 0.61fr 1fr 0.61fr;
    gap: 18px;
 
}

.grid50 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 22px;
    padding-bottom: 22px;

}

.controllerBtn {
    border: 1px solid rgb(135,142,145, 0.2);
    background: #EFEFF0;
    color: #878E91;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    /* font-weight: 300; */
    text-align: center;
    flex-direction: column;
    gap: 10px;

    
} 

.controllerBtn.controllerBtnOff {
    opacity: 0.25;
}

.controllerScreenshot {
    width: 100%;
    height: auto;
    transition: all 0.3s ease;  
    /* background: rgba(255,255,255,0.35); */

    overflow: hidden;

}

.controllerScreenshot img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.3s ease;
    float: left;
    /* aspect-ratio: 1.78; */
    /* opacity: 0; */
}

.splitBtn.controllerBtn {
    padding: 0;
}

.pageBtn {
    font-size: 30px;
    font-weight: 700;
    font-family: 'GothamBold';
    color: #3C4347;
    height: calc(100% - 42px);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: 282px; */
}

.notesContainer {
    width: 100%;
}

.notes {
    height: 100%;
    width: calc(100% - 40px);
    border-radius: 10px;
    background: #EFEFF0;
    min-height: 250px;
    padding: 10px 20px;
    color: #3C4347;
    /* display: inline-block; */
}

.notes li {
    line-height: 200%;
}


.splitBtn {
    display: grid;
    grid-template-columns: 60px 1fr;
}

.splitBtn.right {
    display: grid;
    grid-template-columns: 1fr 60px;
}

.splitBtn .pageBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgb(135,142,145, 0.2);
}

.splitBtn.right .pageBtn {
    border-left: none;
    border-right: 1px solid rgb(135,142,145, 0.2);
}

/* .scrollArea {
    padding: 0 10px;
} */

.moduleContainer{
    position: absolute;
   overflow: hidden;
    width:100vw;
    height: 100vh;
    left:0;
    top:0;
    background-color: transparent;
    pointer-events: none;
    z-index: 2;

}

.moduleContainer:global(.inactive) {
   display: none;
}


.linkButtonContainerParent {
    width: 100%;
    float: left;
    /* position: absolute;
    right: 0; */
   
}

.linkButtonContainerParent h3 {
    color: #3C4347;
}

.linkButtonContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 22px 0;
    overflow-x: scroll; 
}


.linkBtn {
    background: var(--secondary);
    color: white;
    width: calc(25% - 46px);
    border-radius: 10px;
    padding: 12px;
    /* margin: 0 11px; */
    flex-shrink: 0;
}

.stateButtonContainer {
    width: calc(100% - 66px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    /* background: #EFEFF0; */
    padding: 0;
    border-radius: 10px;
    /* position: absolute;
    bottom: 0px; */
    /* border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; */
}

.stateButtonContainer h3 {
    width: 100%;
    color: #3C4347;
    /* color: #878E91; */
   
}

.stateButtons {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: flex-start;
    gap: 12px;
    width: 100%;
    max-height: 162px;
    overflow-y: scroll;
    padding-bottom: 20px;
}

.stateButtons::-webkit-scrollbar {
    display: none;
}

.stateBtn {
    background: white;
    color: #878E91;
    border: #878E91 1px solid;
    width: auto;
    border-radius: 10px;
    padding: 12px 12px;
}
.stateBtn[active="true"]{
    color: var(--primary-accent);
    border-color: var(--primary-accent);
    border-width: 1px;
}

.menuModuleContainer{
    max-width: calc(95px * var(--lerpScale));
    width: 78px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: flex-start;
    border-right:1px solid #878E91;
    pointer-events: all;
}

.menuColRight{
    position: relative;
    -webkit-overflow-scrolling: touch;
    transform:translate3d(0,0,0);
    backface-visibility: hidden;
    background-color: var(--primary-background);
    height: 100%;
    max-height: 100%;;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
     min-width:360px;
     max-width:360px;
     box-shadow: 5px 0 5px rgba(0,0,0,0.15);
     z-index: auto;
}

.moduleLink{
    padding: 10px 0;
    width:32px;
    display: flex;
    align-items: center;
    justify-content: center;   
    cursor: pointer;
    --svg-stroke:var(--primary);
    --svg-stroke-hover:var(--primary-accent);
    
}



.moduleLink:nth-of-type(1){
    padding-top: 0;
}
.moduleLink :global(.themeable){
    transition: stroke 0.25 ease-in-out
}
.moduleLink:hover :global(.themeable),
.moduleLink[highlighted="true"] :global(.themeable)
{
    /* stroke:var(--svg-stroke-hover) !important */
    stroke: var(--secondary);
}
.moduleLink:nth-child(1){
    margin-top:0
}
.moduleLink :global(img),
.moduleLink svg{
    height:100%;
    width:100%;
}

.menuLogo{
    object-fit: contain;
    height:15%;
    width:calc(100% - 134px);
    object-position: center;
    display: block;
    margin:0 auto;
    max-width: 160px;
    margin: 0 0 10px 80px;
}


.linksContainer{
    position: relative;
    left:var(--sideIndent);
    top:0px;
    pointer-events: all;
    height:100%;
    /* width: 300px; */
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    margin-top: 0px;
    max-width: calc(100% - var(--sideIndent));
    /* top: 190px; */
}
/* 
.menuModuleContainer .linksContainer {
    top: 180px;
} */

.linksContainer.moduleLinksContainer {
    top: 190px;
}

.moduleContainer:global(.inactive) .linksContainer{
    display: none;
}
.linksScrollContainer{
    width: 190px;
    text-overflow: ellipsis;
    overflow: scroll;
}

.linksContainer :global(h3){
    margin-top: 0px;
    font-size: 20px;
    color: var(--primary)
}

.moduleThumbsScrollContainer{
    width:calc(100% -  20px);
    margin:25px 10px;
}

.moduleThumbsScrollContainerGradient{
    position: fixed;
    left:440px;
    width:100vw;
    height:140px;
    background: linear-gradient(0deg, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%);
    bottom: 0;   
}
.moduleNextPrevBtnContainer{
    position: absolute;
    bottom: 0;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    left:323px;
    right:0;
    gap: 16px;
    pointer-events: all;
}


.galleryThumbnails {
    float: left;
    width: calc(100% - 40px);
    padding: 0 0 100px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
 
}

.galleryThumbnail {
    height: auto;
    width: 100%;

    transition: all 0.3s ease;
    aspect-ratio: 1.778;

}   

.galleryThumbnail:global(.linkOn) img{
    border: 1px solid var(--secondary);
}


.galleryThumbnail img{
  object-fit: cover;
  width: 100%;
  height: 100%;
 float: left;
 border-radius: 20px;
 border: 1px solid transparent;
}

.stackBaseThumb {
    color: #0F3057;
    margin: 0 10px;
}

.stackBaseThumb .stackBaseHeading{
    padding: 5px 0;
    font-size: 18px;
    line-height: 33px;
    letter-spacing: 0px;
    color: #0F3057;
}

.stackBaseThumb img {
    height: 220px;
    width: auto;
    border: 1px solid rgba(135, 142, 145, .35);
    border-radius: 10px;
}

.stackBaseBtn {
    /* border: 1px solid rgba(135, 142, 145, .35); */
    border-radius: 10px;
    padding: 10px 20px;
    background-color: var(--primary-accent);
    float:left;
    margin: 0 0 40px 0;
    color: #fff;
}


.stackThumbnails {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: 1fr;
    gap: 22px;
    align-items: flex-start;
  
    
}
.stackThumbnail{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* max-width: 198px; */
}
.stackThumbnailContainer{
    border: 1px solid rgba(135, 142, 145, .35);
    background-color: #EFEFF0;
    border-radius: 10px;
    /* height: 165px;
    width: 196px; */
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 198 / 165;
}
.stackThumbnailHolder{
    display: flex;
    align-items: center;
    justify-content: center;
    width:95%;
    height:95%;
    background-color: #EFEFF0;
}
.stackThumbnailHolder:active :global(.round-btn){
    transform: scale(0.75);
}

.stackThumbnail img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}


.headingStack{
    font-size: 20px;
    line-height: 33px;
    letter-spacing: 0px;
    color:#0F3057;
    margin-bottom: 10px;
    align-items: center;
    display:flex;    
}
.headingStackBtn{
    display: flex;
    align-items: center;
    flex-shrink: 1;
}
.headingStackBtn:active {
    transform: scale(0.75);
}
/* style the drop down */
.headingStack.dropdown{
    /* border: none;
    --secondary:#0F3057; */
    margin:0
}
.headingStack :global(.heading){ flex-grow: 1;}
.stackThumbnail[active="true"] .captionStack{
    color:var(--primary-accent);
}

.markerButton{
    color: white;
    width: 160px;
    height:40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-overflow: ellipsis;
    background-color: #FF4C55;
    border-radius: 10px;
    margin-top: 20px;
    padding: 8px;
}

.backBtnRow {
    background: white;
    top: 0;
    /* left: 350px; */
    width: calc(100vw - 392px);
    padding: 22px 22px 22px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 10;
    position: absolute;
}
.backBtnRowOver {
    background: transparent;
    width: calc( 100% - 22px );
    pointer-events: none;
    left:0;
}

.backBtnRow .pillBtn {
    padding: 0 16px;
    pointer-events: all;
    position: fixed;
    top: 22px;
    width: 180px;
    border-radius: 20px;
    background: white;
    filter: drop-shadow(0px 3px 20px rgba(0,0,0, 0.34));
}

.hasBackBtnRow {
    padding-top: 76px;
}

/* Joystick */

.joystickHolder {
    position: absolute;
    width: calc(100vw - 350px);
    height: 90vh;
    padding: 0;
    color: var(--primary);
    float: left;
    left: 0;
    top: 6px;
    background-image: url('../assets/joystick-bg.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    /* left: 0px; */
}

.joystickHome {
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
}


.joystickCircle circle {
    /* filter: drop-shadow(0px 0px 4px rgba(6, 6, 6, 0.5)); */
}

.galleryPopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: all;
    opacity: 0;
}


.galleryPopup .joystickHolder {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
}

.galleryPopup :global(.matterport), .galleryPopup :global(.external), .galleryPopup :global(.vr) {
    height: 100vh;
    width: 70vw;
    left: 30vw;
    background-color: white;
    position: absolute;
}

.galleryPopup :global(.matterport) .joystickHolder, .galleryPopup :global(.vr) .joystickHolder {
    width: 70vw;
    height: 60vh;
}

.galleryPopup :global(.external) .joystickHolder {
    width: 70vw;
    height: 100vh;
}

/* .galleryPopup :global(.vr) .joystickHolder{
    width: 70vw;
    height: 90vh;
} */

.galleryPopup .buttons {
    width: 100vw;
    left: 0;
}

.galleryPopup .buttons.buttons70 {
    width: 70vw;
    left: 30vw;
}


.videoControls {
    background: #393939;
    display: flex;
    align-items: center;
    justify-content: center;
}

.videoButtons {
    width: 80%;

}

.videoBtnRow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    padding-bottom: 16px;
    height: 382px;
}

.videoBtn {
    background: #9E9E9E;
    color: white;
    border-radius: 10px;
    border: 2px solid #959596;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    min-height: 168px;
    font-family: 'GothamBold';
}

.joystickClose {
    position: fixed;
    left: 0px;
    bottom: 0;
    z-index: 10;
}

.webglMarkerContainer{
    position: absolute;
    right:22px;
    bottom: 0px;
    left: 22px;
    height:0px;
    
}

.webglMarkerContainerButtons{
    position: absolute;
    bottom:95px;
    flex-wrap: wrap;
    right: 0;
    width: auto;
    left:0;
}

.stateBtn.webglMarkerButton{
    
    min-width: 100px;
    padding: 12px 14px;
    white-space: nowrap;
    max-width: 148px;
    overflow: hidden;
    max-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-overflow: ellipsis;
    
}
.stateBtn.webglMarkerButton:focus-visible{
    outline:none;
}
.stateBtn.webglMarkerButton:active{
    color: var(--primary-accent);
    
}
.stateBtn.webglMarkerButton[active="true"]{
    border-width: 1px;
}

.buttons {
    position: fixed;
    bottom: var(--sideIndent);
    width: calc(100vw - 350px);
    left: 350px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 16px; */
}



.pillBtns {
    border-radius: 32px; 
    /* filter: drop-shadow(1px 3px 3px rgba(0,0,0,0.5)); */
    box-shadow: 0px 3px 1px rgb(0 0 0 / 10%);
    background: white;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: var(--sideIndent);
    justify-content: center;
}

.button {
    padding: 10px 20px;
    border-radius: 20px;
    color: #3C4347;
    background-color: #fff;
    width: auto;
    float: left;
    filter: drop-shadow(1px 3px 3px rgba(0,0,0,0.5));
    border: 1px solid #EFEFF0;
    transition: all 0.3s ease;
}


.button.on {
    color: #fff;
    background-color: #0F3557;
}

.roundBtn {
    width: 38px;
    height: 38px;
    filter: drop-shadow(1px 3px 3px rgba(0,0,0,0.5));
    background: white;
    border-radius: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* transform: scale(1.25); */
}


.pillBtn {
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3c4347;
    padding: 0 6px;
}
.pillBtn:active {
    opacity: 0.85;
}
.pillBtn.pillBtnRight {
    padding: 0 6px;
    border-left: 1px solid #e6e6e6;
}

.arrowRowsFlex {
    position: absolute;
    bottom: 50px;
    width: 70vw;
    left: 30vw;
    display: flex;
    justify-content: center;
}

.arrowRows {
    display: grid;
    grid-template-columns: repeat(3, 52px);
    grid-template-rows: repeat(3, 52px);
}


.arrowRow .roundBtn {
    padding: 5px;
    height: auto;
}

.arrowRow {
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrowRow0 {
    grid-column-start: 2;
}
.arrowRow1 {
    grid-row-start: 2;
}

.arrowRow2 {
    grid-row-start: 2;
    grid-column-start: 3;
}

.arrowRow3 {
    grid-row-start: 3;
    grid-column-start: 2;
}


.arrowRow  div {
    margin: 5px 20px;
}

.canvasSwiper {
    height: 140px;
    margin-bottom: 20px;
}
.canvasFrame {
    display: block;
    float: left;
    width: auto;
    height: 134px;
    min-height: auto;
    margin-left:0;
    cursor: pointer;
    transition: opacity 0.3s ease;
    opacity: 0.5;
    aspect-ratio: 16/9;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 10px;
}

.canvasFrame.on {
    opacity: 1;
    /* box-shadow: 0 0 10px rgba(0,0,0,0.25); */
}

.hdFrame {
    position: absolute;
    width: 233px;
    height: 128px;
    border: 3px solid transparent;
    pointer-events: none;
}

.fullFrame {
    position: absolute;
    width: calc(100% - 6px);
    height: 134px;
    border: 3px solid transparent;
    pointer-events: none;
}

.canvasFrame.on .hdFrame {
    border: 3px solid var(--secondary);
}

.canvasFrame.on .fullFrame {
    /* border: 3px dotted #ff4c55; */
}

.canvasFrame img, .pageThumb {
    width: auto;
    height: 100%;
    pointer-events: none;
}

.pageThumb.groupSlide {
    /* margin-left: 20px; */
    border-left: 3px solid var(--secondary)
}


.landingContainer {
    width: calc(100vw - 440px);
    min-height: calc(100vh - 50px);
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 440px;
    z-index: 3;
    padding: 25px 0 15px 0;
    background: white;
    /* background-color: rgba(20,20,20,1); */
    overflow-y: scroll;
    /* background-color: var(--primary-background); */
   
}

  .itemsContainer {
    
    width: calc(100% - 50px);
    /* display: flex; */
    flex-wrap: wrap; 
    position: relative;
    align-content: flex-start;
    height: 90vh;
    margin-bottom: 0px;
    padding-top: 0px;
    /* padding-left: 20px; */
    position: relative;
    top: 0;
    gap: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  
  }

  .itemContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    
    /* aspect-ratio: 1.78/1; */
    /* width: calc(384px*0.61);
    height: calc(247px*0.61); */
    cursor: pointer;
    /* margin-bottom:21px; */

}



.itemContainer img{
    object-fit: cover;
    width: 100%;
    height: auto;
    border: 1px solid #878E91;
    border-radius: 10px;
    display: relative;
    transition: all .25s ease-in-out;
    aspect-ratio: 1.78;
}


  .heading{
    position:absolute;
    color:var(--primary-background) !important;
    color:#0F3057;
    top:56px;
    font-weight: 700;
    font-size: 28px;
    font-family: 'GothamBold';
  }

  .letterbox{
    position: relative;
    width:85%;
    margin: 0 auto;
    z-index: 1;
}


.caption{
    color: var(--primary-background) !important;
    /* color:#0F3057; */
    /* width: 1--primary-background
    text-overflow: ellipsis;
    /* margin-top:3px; */
    text-align: left;
    font-size: 16px;
    transition: all .25s ease-in-out;
    float: left;
    /* margin-top: -23px; */
    padding: 4px 2px;
    width: calc(100% - 4px);
    /* background: rgba(255,255,255,0.8); */
}

.categoryCaption {
    /* display: none; */
}

.mapControls {
    /* width: 318px; */
    height: calc(calc(38px + calc(var(--lerpAspect) * 4px)) * var(--lerpScale));
    /* background-color: var(--secondary-background); */
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 1px rgba(0,0,0,0.1);
    position: fixed;
    bottom: var(--sideIndent);
}

.pinBtn {
    padding: 0 4px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-right: 1px solid #E6E6E6;
    border-left: 1px solid #E6E6E6;
    width: calc(calc(38px + calc(var(--lerpAspect) * 4px)) * var(--lerpScale));
}



.zoomOutBtn, .zoomInBtn {
    padding: 0 5px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(calc(38px + calc(var(--lerpAspect) * 4px)) * var(--lerpScale));
    align-items: center;
    cursor: pointer;
}

.zoomOutBtn.disabled, .zoomInBtn.disabled, .pinBtn.disabled {
    opacity: 0.3;
}

.helpPanel {
    width: calc(30vw - 80px);
    position: absolute;
    left: 0;
    height: 100vh;
    padding: 40px;
    background: #EFEFF0;
    color: #0F3557;
    display: flex;
    flex-direction: column;
}

.helpPanel h2 {
    padding-top: 0;
    margin-top: 0;
}

.helpPanel img {
    width: 50%;
    max-width: 75px;
    height: auto;
    padding: 0 0 40px 0;
}

.zoomControlsContainer{
    width: 100%;
    justify-content: center;
    background-color:transparent;
    position: absolute;
    bottom: var(--sideIndent);
    display: flex;
    align-items: center;
}

.zoomControls{
    border-radius: calc(calc(38px + calc(var(--lerpAspect) * 4px)) * var(--lerpScale));
    height: calc(calc(38px + calc(var(--lerpAspect) * 4px)) * var(--lerpScale));
    box-shadow: 0px 3px 1px rgba(0,0,0,0.1);
    margin-right: 12px;
    pointer-events: all;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0 3px 1px rgba(0,0,0,0.1));
    background-color: var(--round-btn-background-color);
}


.zoomControls svg:hover{
    transform: scale(1.15);
}

.zoomControls svg{
    transition: all 0.1s ease-in-out;
    cursor:pointer;
    padding: 0 5px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(calc(38px + calc(var(--lerpAspect) * 4px)) * var(--lerpScale));
}
.zoomControls svg line{
    stroke:var(--svg-stroke) ;
}
.zoomControls svg:hover  line{
    stroke:var(--svg-stroke-hover) ;
}

.separator {
    height:0;
    border-right: 1px solid #e6e6e6;
    width: 2px;
    height: calc(calc(38px + calc(var(--lerpAspect) * 4px)) * var(--lerpScale));
    z-index: 1;
}

.loadingPage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(20,20,20,1);
}



.dPad {
    z-index: 10;
    position: absolute;
    bottom: 14%;
    right: calc(50% - 90px);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
}

.dPadBtn {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: fuchsia;
    cursor: pointer;
    border-radius: 32px; 
    /* filter: drop-shadow(1px 3px 3px rgba(0,0,0,0.5)); */
    box-shadow: 0px 3px 1px rgb(0 0 0 / 10%);
    background: white;
    
}

.dPadBtn :global(#caret_up) {
    transform: rotate(270deg);
}


.dPadBtn :global(#caret_down) {
    transform: rotate(90deg);
}